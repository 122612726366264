import { redirect } from '@tanstack/react-router'
import qs from 'qs'
import i18n from '~/lib/i18n'
import type { RoutePath } from '~/types/shared'
import { removeTrailingSlash } from './string'

type RedirectIfPathnameMatchesParam = {
  pathname: string
  expectedPathname: RoutePath
  redirectTo: RoutePath
}

export function redirectIfPathnamesMatch({
  pathname: pathnameProp,
  expectedPathname,
  redirectTo,
}: RedirectIfPathnameMatchesParam) {
  const pathname = removeTrailingSlash(pathnameProp) as RoutePath

  if (pathname === expectedPathname) {
    // if pathname doesn't include category, redirect to 'general' category
    throw redirect({
      to: redirectTo,
      replace: true,
    })
  }
}

export function customStringifier(searchObj: Record<string, unknown>) {
  return qs.stringify(searchObj, {
    addQueryPrefix: true,
    arrayFormat: 'comma',
    encodeValuesOnly: true,
  })
}

export function customParser(searchString: string) {
  return qs.parse(searchString, {
    ignoreQueryPrefix: true,
    comma: true,
  })
}

/**
 * Matches the provided pathname against static and dynamic routes.
 */
export const getRouteMatch = (routes: Record<string, string>, pathname: string) => {
  for (const route in routes) {
    const dynamicRoutePattern = route.replace(/\$[a-zA-Z]+/g, '[^/]+')
    const regex = new RegExp(`^${dynamicRoutePattern}$`)
    if (regex.test(pathname)) {
      return routes[route] as RoutePath
    }
  }
}

/**
 * Get the page title taking into account all types of routes: static and dynamic
 * .
 * @returns The page title if found, otherwise a not found message.
 */
export const getPageTitleFromPath = (pathname: string, lang = i18n.language) => {
  const routes: Record<string, string> = i18n.getResourceBundle(lang, 'routes') || {}

  // Check for exact match first
  if (routes[pathname]) {
    return routes[pathname]
  }

  // Check for dynamic routes
  const dynamicRoute = getRouteMatch(routes, pathname)

  return dynamicRoute
}
