import type { Action, DynamicPageValue } from './types'
import { ActionTypes } from './types'

const LOCAL_STORAGE_KEY = 'DYNAMIC_PAGE_DATA'
const initialState: DynamicPageValue = {
  data: {},
}

export function initializeState(): DynamicPageValue {
  const localStorageValue = localStorage.getItem(LOCAL_STORAGE_KEY)
  if (localStorageValue !== null) {
    return JSON.parse(localStorageValue)
  }
  saveStateToLocalStorage(initialState)
  return initialState
}

export function dynamicPageReducer(state: typeof initialState, action: Action) {
  switch (action.type) {
    case ActionTypes.UPDATE_DYNAMIC_VALUE:
      return {
        ...state,
        data: {
          ...state.data,
          ...action.data,
        },
      }
    default:
      return state
  }
}

export function dynamicPageReducerLocalStorage(state: typeof initialState, action: Action) {
  const newState = dynamicPageReducer(state, action)
  saveStateToLocalStorage(newState)
  return newState
}

function saveStateToLocalStorage(state: DynamicPageValue) {
  localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(state))
}
