import { useQuery } from '@tanstack/react-query'
import { api } from '~/lib/api'
import { TENANTS_MODULES } from '~/lib/queryKeys'
import type { ApiResponse, TenantsModulesResponse } from '~/types/apiContracts'

async function fetchTenantModules(tenandId: string | null) {
  const res = (await api
    .get(`tenants/tenants/${tenandId}/modules`)
    .json()) as ApiResponse<TenantsModulesResponse>

  return res.data
}

type UseTenantModulesQueryOptions = {
  tenantUuid: string | null
  enabled: boolean
}

export function useTenantModulesQuery({ tenantUuid, enabled }: UseTenantModulesQueryOptions) {
  return useQuery({
    queryKey: [TENANTS_MODULES, tenantUuid],
    queryFn: () => fetchTenantModules(tenantUuid),
    enabled: !!tenantUuid && enabled,
  })
}
