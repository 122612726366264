import {
  IconChevronDown,
  IconChevronUp,
  IconDeviceFloppy,
  IconPlus,
  IconRefresh,
} from '@tabler/icons-react'
import type { FormikConfig } from 'formik'
import { FieldArray, Form, FormikProvider, useFormik } from 'formik'
import mixpanel from 'mixpanel-browser'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from '~/components/UI/Button'
import { Modal } from '~/components/UI/Modal'
import { useFiltersOptionsQuery } from '~/hooks/queries'
import type { Filter, FilterEmpty } from '~/providers/FiltersProvider'
import { useFilters } from '~/providers/FiltersProvider'
import { validateFormValues } from '~/utils/formik'
import { FilterItem } from './FilterItem'
import { FiltersSets } from './FiltersSets'
import { FiltersSetForm } from './FitersSetForm'
import { convertOperatorByMode, validationSchema } from './helpers'
import type { MainFiltersFormikValues, MainFiltersModalProps } from './types'

export const MainFiltersModal = (props: MainFiltersModalProps) => {
  const { t } = useTranslation(['common', 'filter'])
  const { basicFilters, upsertFilters, clearFilters, dateFilters } = useFilters(props.moduleId)

  const [isSavedFiltersVisible, setIsSavedFiltersVisible] = useState(false)
  const [isFiltersSetFormVisible, setIsFiltersSetFormVisible] = useState(false)

  const filtersOptionsQuery = useFiltersOptionsQuery(props.moduleId)

  const generateEmptyFilter = (): FilterEmpty => ({
    id: crypto.randomUUID(),
    operator: 'in',
    value: [],
    field: '',
  })

  const cleanFilters = (filters: Filter[]) => {
    return filters.map((filter) => ({
      ...filter,
      value: ['in', 'not in', 'greater than', 'less than'].includes(filter.operator)
        ? filter.value
        : [],
    })) as Filter[]
  }

  const submitFilters: FormikConfig<MainFiltersFormikValues>['onSubmit'] = (
    values,
    { setSubmitting },
  ) => {
    const cleanedFilters = cleanFilters(values.filters as Filter[])

    upsertFilters([...cleanedFilters, ...dateFilters])
    setSubmitting(false)
    props.onClose()
  }

  const formik = useFormik<MainFiltersFormikValues>({
    initialValues: {
      filters: basicFilters.length > 0 ? basicFilters : [generateEmptyFilter()],
    },
    validate: (values) => {
      return validateFormValues(values, validationSchema)
    },
    onSubmit: (values, helpers) => {
      if (props.confirmFiltersModalSubmit) {
        props.confirmFiltersModalSubmit(() => submitFilters(values, helpers))
        return
      }

      submitFilters(values, helpers)

      mixpanel.track('Filters applied')
    },
    validateOnMount: true,
  })

  const hideSavedFilters = () => {
    setIsSavedFiltersVisible(false)
  }

  const showSavedFilters = () => {
    setIsSavedFiltersVisible(true)
    setIsFiltersSetFormVisible(false)
  }

  const showFiltersSetForm = () => {
    setIsFiltersSetFormVisible(true)
    hideSavedFilters()
  }

  const hideFiltersSetForm = () => {
    setIsFiltersSetFormVisible(false)
  }

  /// TODO: This approach is necessary because some operator types need to be renamed during filter creation. However, the backend for get-items hasn’t been updated yet, so this adjustment is temporarily handled on the front-end.
  const replaceFilters = (filters: Filter[]) => {
    const newFilters = filters.map(({ field, operator, ...rest }) => {
      return {
        ...rest,
        field: filtersOptionsQuery.data?.find((column) => {
          return column.id === +field
        })?.field,
        operator: convertOperatorByMode(operator),
      }
    })
    formik.setFieldValue('filters', newFilters)
  }

  const clearAllFilters = () => {
    formik.setFieldValue('filters', [generateEmptyFilter()])
    clearFilters()
  }

  const filteredFilters = cleanFilters(formik.values.filters as Filter[])

  return (
    <FormikProvider value={formik}>
      <Modal.Root open={props.open} disablePadding className="w-[74%]" onClose={props.onClose}>
        <Form>
          <Modal.Header
            title={`${t('filters')}:`}
            className="py-4 pe-6 ps-10"
            customContent={
              <div className="flex flex-grow justify-end">
                <Button
                  onClick={clearAllFilters}
                  variant="primary-text"
                  className="font-normal text-night-600 hover:text-night-800"
                  startIcon={IconRefresh}
                >
                  {t('clearAllFilters')}
                </Button>
              </div>
            }
            onClose={props.onClose}
          />
          <Modal.Content>
            <FieldArray name="filters">
              {(arrayHelpers) => (
                <div className="flex flex-col divide-y divide-night-100 @container">
                  {formik.values.filters.map((filter, index) => {
                    return (
                      <FilterItem
                        moduleId={props.moduleId}
                        key={filter.id}
                        filter={filter}
                        index={index}
                        arrayHelpers={arrayHelpers}
                        totalFilters={formik.values.filters.length}
                        clearAllFilters={clearAllFilters}
                        fieldOptions={filtersOptionsQuery.data || []}
                      />
                    )
                  })}
                </div>
              )}
            </FieldArray>
          </Modal.Content>
          {/* added relative and -z-1 to fix overlapping bug with select menus from above */}
          <Modal.Footer className="@container">
            <div className="flex flex-col-reverse items-center gap-4 @3xl:flex-row @3xl:justify-between">
              <div className="grid w-full grid-cols-2 gap-4 lg:flex lg:w-auto">
                <Button
                  variant="ghost"
                  className="text-sm"
                  onClick={isSavedFiltersVisible ? hideSavedFilters : showSavedFilters}
                  startIcon={isSavedFiltersVisible ? IconChevronUp : IconChevronDown}
                  size="medium"
                >
                  {t(isSavedFiltersVisible ? 'hideSavedFilters' : 'viewSavedFilters')}
                </Button>
                <Button
                  variant="ghost"
                  className="text-sm"
                  onClick={showFiltersSetForm}
                  startIcon={IconDeviceFloppy}
                  disabled={!formik.isValid || formik.isSubmitting}
                  size="medium"
                >
                  {t('saveFilters')}
                </Button>
              </div>
              <div className="grid w-full grid-cols-2 gap-4 lg:flex lg:w-auto">
                <FieldArray name="filters">
                  {({ push }) => (
                    <Button
                      variant="secondary"
                      onClick={() => push(generateEmptyFilter())}
                      startIcon={IconPlus}
                      size="large"
                    >
                      {t('addFilter')}
                    </Button>
                  )}
                </FieldArray>
                <Button
                  type="submit"
                  disabled={!formik.isValid || formik.isSubmitting}
                  isLoading={formik.isSubmitting}
                  size="large"
                >
                  {t('action.apply')}
                </Button>
              </div>
            </div>
            {isSavedFiltersVisible && (
              <FiltersSets moduleId={props.moduleId} onClickFilterSet={replaceFilters} />
            )}
          </Modal.Footer>
        </Form>
        {isFiltersSetFormVisible && (
          <FiltersSetForm
            moduleId={props.moduleId}
            filters={filteredFilters}
            onCancel={hideFiltersSetForm}
            onSubmitSuccess={showSavedFilters}
            fieldOptions={filtersOptionsQuery.data || []}
          />
        )}
      </Modal.Root>
    </FormikProvider>
  )
}
