import { type FirebaseOptions, initializeApp } from 'firebase/app'
import type { UserCredential } from 'firebase/auth'
import {
  GoogleAuthProvider,
  OAuthProvider,
  signOut as firebaseAuthSignOut,
  getAdditionalUserInfo,
  getAuth,
  signInWithCustomToken,
  signInWithPopup,
} from 'firebase/auth'

// See: https://firebase.google.com/docs/web/learn-more#config-object
export const firebaseOptions: FirebaseOptions = {
  apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
  authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
  projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
  storageBucket: `${import.meta.env.VITE_FIREBASE_PROJECT_ID}.appspot.com`,
  messagingSenderId: import.meta.env.VITE_FIREBASE_MESSSAGE_SENDER_ID,
  appId: import.meta.env.VITE_FIREBASE_APP_ID,
}

export const popupClosedByUserErrorCode = 'auth/popup-closed-by-user'

// Initialize Firebase
export const firebaseApp = initializeApp(firebaseOptions)

// Initialize Firebase Authentication and get a reference to the service
export const firebaseAuth = getAuth(firebaseApp)

const providerGoogle = new GoogleAuthProvider()
providerGoogle.setCustomParameters({
  // whenever a user interacts with the provider, we force them to select an account
  prompt: 'select_account',
})

const providerMicrosoft = new OAuthProvider('microsoft.com')

/**
 * The returned user credential might not have the tenant set.
 * @returns
 */
export async function signInWithGooglePopup() {
  const userCredential = await signInWithPopup(firebaseAuth, providerGoogle)

  const isNewUser = !!getAdditionalUserInfo(userCredential)?.isNewUser

  return { appToken: userCredential.user.accessToken, isNewUser }
}

/**
 * The returned user credential might not have the tenant set.
 * @returns
 */
export async function signInWithMicrosoftPopup() {
  const userCredential = await signInWithPopup(firebaseAuth, providerMicrosoft)

  const oauthAccessToken = (
    userCredential as UserCredential & { _tokenResponse: { oauthAccessToken: string } }
  )._tokenResponse.oauthAccessToken
  localStorage.setItem('user.oauthToken', oauthAccessToken)

  const isNewUser = !!getAdditionalUserInfo(userCredential)?.isNewUser

  return { appToken: userCredential.user.accessToken, isNewUser }
}

/**
 * @param token Specific token from the backend to get firebase auth token for tenant.
 *
 *@see: https://cloud.google.com/identity-platform/docs/multi-tenancy-authentication#creating_custom_tokens
 */
export function signInWithTenantInFirebase(token: string, tenantId: string) {
  firebaseAuth.tenantId = tenantId
  return signInWithCustomToken(firebaseAuth, token)
}

/**
 *
 * @see https://firebase.google.com/docs/auth/admin/verify-id-tokens#retrieve_id_tokens_on_clients
 */
export function refreshAuthToken() {
  return firebaseAuth.currentUser?.getIdToken(true) ?? null
}

export function signOut() {
  return firebaseAuthSignOut(firebaseAuth)
}

export function getAppToken() {
  return firebaseAuth.currentUser?.accessToken || null
}

export function getAppTenantToken() {
  return firebaseAuth.currentUser?.tenantId ? firebaseAuth.currentUser.accessToken : null
}
