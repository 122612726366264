import { useCallback, useMemo, useReducer } from 'react'
import { DynamicPageContext } from './DynamicPageContext'
import { dynamicPageReducerLocalStorage, initializeState } from './helpers'
import { ActionTypes } from './types'
import type { DynamicPageContextValue, DynamicPageProviderProps, DynamicValue } from './types'

export const DynamicPageProvider = ({ children }: DynamicPageProviderProps) => {
  const [dynamicPageData, dispatch] = useReducer(
    dynamicPageReducerLocalStorage,
    undefined,
    initializeState,
  )

  const updateDynamicState = useCallback((k: string, value: DynamicValue) => {
    dispatch({ type: ActionTypes.UPDATE_DYNAMIC_VALUE, data: { [k]: value } })
  }, [])

  const contextValue: DynamicPageContextValue = useMemo(() => {
    return {
      data: dynamicPageData.data,
      updateDynamicState,
    }
  }, [dynamicPageData, updateDynamicState])

  return <DynamicPageContext.Provider value={contextValue}>{children}</DynamicPageContext.Provider>
}
