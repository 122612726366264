import clsx from 'clsx'
import type { ContainerWithClassName } from '~/types/shared'

interface PageTopbarRootProps extends ContainerWithClassName {
  showSkeleton?: boolean
}

const ROOT_ID = 'page-topbar-root'

export const PageTopbarRoot = ({ children, className, showSkeleton }: PageTopbarRootProps) => {
  return (
    <div
      id={ROOT_ID}
      className={clsx(
        'flex min-h-16 flex-shrink-0 flex-col items-center justify-between gap-2 border-b border-b-night-100 bg-white md:px-6 lg:flex-row lg:gap-4',
        className,
      )}
    >
      {showSkeleton ? (
        <div className="flex w-full justify-between gap-3">
          <div className="h-3 w-40 animate-pulse rounded-xl bg-night-100" />
          <div className="h-3 w-40 animate-pulse rounded-xl bg-night-100" />
        </div>
      ) : (
        children
      )}
    </div>
  )
}

PageTopbarRoot.ROOT_ID = ROOT_ID
