import { createFileRoute } from '@tanstack/react-router'
import { redirectIfPathnamesMatch } from '~/utils/routing'

export const Route = createFileRoute('/_layout/administration/configuration/')({
  beforeLoad: ({ location }) => {
    redirectIfPathnamesMatch({
      pathname: location.pathname,
      expectedPathname: '/administration/configuration',
      redirectTo: `/administration/configuration/general-parameters`,
    })
  },
})
