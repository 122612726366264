export type DynamicValue =
  | number
  | string
  | boolean
  | object
  | Array<number | string | object>
  | null

export type DynamicObject = Record<string | number, DynamicValue>

export type DynamicPageProviderProps = {
  children: React.ReactNode
}

export type DynamicPageContextValue = {
  data: DynamicObject
  updateDynamicState: (key: string, value: DynamicValue) => void
}

export type DynamicPageValue = {
  data: DynamicObject
}

export enum ActionTypes {
  UPDATE_DYNAMIC_VALUE = 'ADD_DYNAMIC_VALUE',
}

type AddDynamicValueActionType = {
  type: ActionTypes.UPDATE_DYNAMIC_VALUE
  data: DynamicObject
}

export type Action = AddDynamicValueActionType
