import type { User } from 'firebase/auth'
import ky from 'ky'
import type { Permission } from '~/types/apiContracts'
import type { RoutePath } from '~/types/shared'

export const SIGN_OUT_KEY = 'signOut'

/**
 * Unlike other providers supported by Firebase Auth,
 * Microsoft does not provide a photo URL and instead,
 * the binary data for a profile photo has to be requested via Microsoft Graph API.
 */
export async function fetchImageMicrosoft(token: string) {
  const res = await ky
    .get(`https://graph.microsoft.com/v1.0/me/photo/$value`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .blob()

  return URL.createObjectURL(res)
}

function convertRoutePathToModuleCodes(path: RoutePath): [string, string] {
  const parts = path.split('/').filter(Boolean)

  // Handle special cases for "administration" groups
  if (
    parts[0] === 'administration' &&
    (parts[1] === 'access-control' || parts[1] === 'configuration')
  ) {
    return [parts[0], parts[2]]
  }

  return [parts[0], parts[1]]
}

export function checkModuleAccess(path: RoutePath, permissions: Permission[]) {
  if (!permissions.length) {
    return false
  }

  const convertedPath = convertRoutePathToModuleCodes(path)
  const [moduleCode, submoduleCode] = convertedPath

  if (!moduleCode) {
    return false
  }

  if (!submoduleCode) {
    return permissions.some(
      (permission) => permission.active && permission.code.split('.')[0] === moduleCode,
    )
  }

  return permissions.some(
    (permission) => permission.active && permission.code === `${moduleCode}.${submoduleCode}`,
  )
}

export const syncUserDetails = async (user: User) => {
  // user details come from the sign in with google popup,
  // but no on page refresh, so we store them in localStorage
  // to persist them
  const storedDisplayName = localStorage.getItem('user.displayName')
  const storedEmail = localStorage.getItem('user.email')
  const storedPhotoURL = localStorage.getItem('user.photoURL')
  const storedUserToken = localStorage.getItem('user.oauthToken')

  let displayName = user.displayName
  let email = user.email
  let photoURL = user.photoURL

  if (!photoURL && !storedPhotoURL && storedUserToken) {
    try {
      photoURL = await fetchImageMicrosoft(storedUserToken)
    } catch (error) {
      photoURL = null
    }
  }

  if (displayName != null && storedDisplayName !== displayName) {
    localStorage.setItem('user.displayName', displayName)
  }

  if (email != null && storedEmail !== email) {
    localStorage.setItem('user.email', email)
  }

  if (photoURL != null && storedPhotoURL !== photoURL) {
    localStorage.setItem('user.photoURL', photoURL)
  }

  displayName ??= storedDisplayName
  email ??= storedEmail
  photoURL ??= storedPhotoURL

  return { ...user, displayName, email, photoURL }
}
