import { useQuery } from '@tanstack/react-query'
import { api, stringifyQueryParams } from '~/lib/api'
import { FILTER_COLUMNS_BY_DATAMART } from '~/lib/queryKeys'
import type { ApiResponse, ConfigColumnsResponse } from '~/types/apiContracts'

type GetColumnsByDatamartParams = {
  datamart: string | null
  search: string
}

const getColumnsByDatamart = async ({ datamart, search }: GetColumnsByDatamartParams) => {
  const searchParams = stringifyQueryParams({
    search,
  })

  const res = (await api
    .get(`configs/query-builder/columns/datamart/${datamart}`, {
      searchParams,
    })
    .json()) as ApiResponse<ConfigColumnsResponse>
  return res.data
}

export const useColumnsByDatamartQuery = (params: GetColumnsByDatamartParams) => {
  return useQuery({
    queryKey: [FILTER_COLUMNS_BY_DATAMART, params],
    queryFn: () => getColumnsByDatamart(params),
    enabled: !!params.datamart,
    select: (data) => {
      return {
        items: data.items,
        total: data.items.length * data.num_pages, //TODO: get this prop from the API
      }
    },
  })
}
