import { useQuery } from '@tanstack/react-query'
import { api } from '~/lib/api'
import { AUTH_LOGIN_TENANT } from '~/lib/queryKeys'
import type { ApiResponse } from '~/types/apiContracts'

type AuthLoginTenant = {
  token: string
  provider_tenant_id: string
}

async function fetchAuthLoginTenant(tenantId: string | null) {
  const res = (await api
    .get(`auth/login/tenants/${tenantId}`)
    .json()) as ApiResponse<AuthLoginTenant>

  return res.data
}

export function useAuthLoginTenantQuery(tenantId: string | null) {
  return useQuery({
    queryKey: [AUTH_LOGIN_TENANT, tenantId],
    queryFn: () => fetchAuthLoginTenant(tenantId),
    enabled: false, // we'll fetch this manually
    gcTime: 0,
  })
}
