import { useLocationPathname } from '~/hooks/useLocationPathname'
import { removeTrailingSlash } from '~/utils/string'
import type { TabItemLink } from '../UI/Tabs'
import { Tabs } from '../UI/Tabs'

interface TabsContentProps {
  tabsItems: TabItemLink[]
}

export const TabsContent = ({ tabsItems }: TabsContentProps) => {
  const locationPathname = useLocationPathname()
  const trimmedPathname = removeTrailingSlash(locationPathname)

  const currentTab =
    tabsItems.find((item) => trimmedPathname.startsWith(String(item.to)))?.to || null

  if (!tabsItems.length) {
    return
  }

  return (
    <div className="self-end">
      <Tabs value={currentTab} items={tabsItems} showContent={false} asNav />
    </div>
  )
}
