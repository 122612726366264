import { StrictMode } from 'react'
import ReactDOM from 'react-dom/client'
import { App } from './App'
import { initBugsnag } from './lib/bugsnag'
import { initLaunchDarkly } from './lib/launchDarkly'
import { enableMockingWithMSW } from './lib/msw'

// biome-ignore lint/nursery/useComponentExportOnlyModules: <explanation>
const ErrorBoundary = initBugsnag()

// biome-ignore lint/nursery/useComponentExportOnlyModules: <explanation>
const LDProvider = await initLaunchDarkly()

await enableMockingWithMSW()

const rootElement = document.getElementById('app')

if (rootElement && !rootElement.innerHTML) {
  const root = ReactDOM.createRoot(rootElement)

  let app = <App />

  if (ErrorBoundary) {
    app = <ErrorBoundary>{app}</ErrorBoundary>
  }

  if (LDProvider) {
    app = <LDProvider>{app}</LDProvider>
  }

  root.render(<StrictMode>{app}</StrictMode>)
}
