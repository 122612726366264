import { createFileRoute } from '@tanstack/react-router'
import { zodSearchValidator } from '@tanstack/router-zod-adapter'
import { z } from 'zod'
import { listSearch } from '~/lib/searchSchemas'

const scenarioCreationSearchParams = z
  .object({
    name: z.string().min(3),
    description: z.string().optional(),
  })
  .and(listSearch)

export type ScenarioCreationSearchParams = z.infer<typeof scenarioCreationSearchParams>

export const Route = createFileRoute('/_layout/planning/scenarios/create/')({
  validateSearch: zodSearchValidator(scenarioCreationSearchParams),
})
