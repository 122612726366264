import { redirect } from '@tanstack/react-router'
import type { Options } from 'ky'
import ky from 'ky'
import qs from 'qs'
import type { ApiResponseError } from '~/types/apiContracts'
import { checkIfTokenIsVeryOld } from '~/utils/checkIfTokenIsVeryOld'
import { getAppToken, refreshAuthToken, signOut } from './firebase'
import { queryClient } from './queryClient'

const isTest = import.meta.env.MODE === 'test'

const kyOptions: Options = {
  prefixUrl: import.meta.env.VITE_API_URL,
  hooks: {
    beforeRequest: [
      // add here any logic that should run before each request
      // like adding headers or query params
      (request) => {
        request.headers.set('Authorization', `Bearer ${getAppToken()}`)
      },
    ],
    // add here any logic that should run before each error, like customizing the error
    beforeError: [
      // this will extract the API error object from the response
      async (error) => {
        const { response } = error

        if (response) {
          try {
            // biome-ignore lint/suspicious/noExplicitAny: <explanation>
            const json = await response.json<any>()

            if ('error' in json) {
              error.apiError = json.error as ApiResponseError
            }
            if ('data' in json) {
              error.apiErrorData = json.data
            }
          } catch (error) {
            console.error('Error parsing error response JSON')
          }
        }

        return error
      },
    ],
    afterResponse: [
      async (request, _options, response) => {
        const requestToken = request.headers.get('Authorization')

        const isTokenVeryOld = checkIfTokenIsVeryOld(requestToken)

        if (isTokenVeryOld) {
          await signOut()
          queryClient.clear()
          redirect({ to: '/login' })

          throw new Error('Token is very old, aborting request')
        }

        if (response.status === 401) {
          // Get a fresh token
          const token = await refreshAuthToken()

          if (!token) {
            return response
          }

          // Retry with the token
          request.headers.set('Authorization', `Bearer ${token}`)
          return ky(request)
        }
      },
    ],
  },
}

if (isTest && kyOptions.hooks) {
  // remove the following hooks for test environment
  kyOptions.hooks.beforeRequest = []
  kyOptions.hooks.afterResponse = []
}

export const api = ky.create({
  ...kyOptions,
  retry: 0, // retries should be handle by tanstack-query
})

export const mockApi = ky.extend({
  prefixUrl: import.meta.env.VITE_MOCK_API_URL,
})

export function stringifyQueryParams(params: Record<string, unknown>) {
  return qs.stringify(params, { indices: false, addQueryPrefix: false })
}
