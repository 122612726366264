import type {
  GridColDef,
  GridColumnGroup,
  GridLeafColumn,
  GridValidRowModel,
} from '@mui/x-data-grid-premium'
import type { GridApiPremium } from '@mui/x-data-grid-premium/models/gridApiPremium'
import type { GridInitialStatePremium } from '@mui/x-data-grid-premium/models/gridStatePremium'
import type { MutableRefObject } from 'react'
import { SIGN_OUT_KEY } from '~/providers/AuthProvider/helpers'
import { GRID_ACTIONS_FIELD } from '~/utils/dataGrid'
import type { DataTableColumnWidths, DataTableCustomProps } from './types'

export const dataTableCustomPropsKeys = [
  'tableId',
  'enableStatePersistence',
  'rootClassName',
  'tableContainerClassName',
  'isLoadingFirstTime',
  'hideExportDialogColumnsSection',
  'hideExportDialogDateRangeSection',
  'exportButtonProps',
  'columnsButtonContainerClassName',
  'extraContent',
  'disableScrollAnimation',
  'disableContainerBorder',
  'disableContainerPadding',
  'paginationClassName',
  // this is a way to ensure that all keys are included, not 100% safe anyway
] as const satisfies (keyof DataTableCustomProps)[]

const dataGridStateKeyPrefix = 'data-grid-state-'

export function getDataGridStateFromLocalStorage(
  dataGridStateKey: string,
): GridInitialStatePremium | null {
  const stateFromLocalStorage = localStorage?.getItem(dataGridStateKeyPrefix + dataGridStateKey)
  return stateFromLocalStorage ? JSON.parse(stateFromLocalStorage) : null
}

export function saveDataGridStateToLocalStorage(
  dataGridStateKey: string,
  apiRef: MutableRefObject<GridApiPremium>,
) {
  if (localStorage && localStorage.getItem(SIGN_OUT_KEY) == null && apiRef?.current?.exportState) {
    const currentState = apiRef.current.exportState()
    localStorage.setItem(dataGridStateKeyPrefix + dataGridStateKey, JSON.stringify(currentState))
  }
}

export function mapColumnWidths(columns: readonly GridColDef[]): DataTableColumnWidths {
  return columns.reduce<DataTableColumnWidths>((obj, col) => {
    if (col.width != null) {
      obj[col.field] = col.width
    }

    if (obj[col.field] == null && col.minWidth != null) {
      obj[col.field] = col.minWidth
    }

    return obj
  }, {})
}

export function checkIfColumnsAreEmpty<R extends GridValidRowModel>(
  columns: readonly GridColDef<R>[],
) {
  return columns.length === 0 || (columns.length === 1 && columns[0].field === GRID_ACTIONS_FIELD)
}

/**
 * Consolidates the columns fields by merging the ordered fields with the current actual fields.
 * It preserve the order of the ordered fields and insert the new fields at the best position.
 */
export function consolidateColumnFields(orderedFields: string[], currentFields: string[]) {
  const currentFieldsLookup: Record<string, true> = {}

  for (const item of currentFields) {
    currentFieldsLookup[item] = true
  }

  // filter only items that exist in currentFields
  const filteredOrderedFields = orderedFields.filter((item) => currentFieldsLookup[item])

  const orderedFieldsLookup: Record<string, true> = {}

  for (const item of filteredOrderedFields) {
    orderedFieldsLookup[item] = true
  }

  const result: string[] = [...filteredOrderedFields]

  currentFields.forEach((item, idx) => {
    const isNewField = !orderedFieldsLookup[item]
    if (!isNewField) return

    const prevIdx = idx - 1

    // check if prev item exists and exists in the orderedFields, if exists, insert the new item after the prev item

    if (prevIdx >= 0) {
      const prevItem = currentFields[prevIdx]

      const prevItemIndexInOrderedFields = result.indexOf(prevItem)

      if (prevItemIndexInOrderedFields !== -1) {
        const bestIdxToInsert = prevItemIndexInOrderedFields + 1
        result.splice(bestIdxToInsert, 0, item)
        return
      }
    }

    const nextIdx = idx + 1

    if (nextIdx >= 0) {
      const nextItem = currentFields[nextIdx]

      const nextItemIndexInOrderedFields = result.indexOf(nextItem)

      if (nextItemIndexInOrderedFields !== -1) {
        const bestIdxToInsert = nextItemIndexInOrderedFields
        result.splice(bestIdxToInsert, 0, item)
        return
      }
    }

    // best position not found so insert at the end
    result.push(item)
  })

  return result
}

export function consolidateColumnsFieldsWithGroupModel(
  currentFields: string[],
  columnGroupModel: GridColumnGroup[],
): string[] {
  const groupedFields = columnGroupModel.flatMap(({ children }) =>
    children.map((value) => (value as GridLeafColumn).field),
  )

  const groupedFieldsSet = new Set(groupedFields)

  const fieldsWithoutGroupModel = currentFields.filter((field) => !groupedFieldsSet.has(field))

  const fieldsWithGroupModelOrder = currentFields.filter((field) => groupedFieldsSet.has(field))

  return [...fieldsWithoutGroupModel, ...fieldsWithGroupModelOrder]
}

export function consolidateColumnWidths(
  currentColumnWidths: DataTableColumnWidths,
  newColumnWidths: DataTableColumnWidths,
) {
  const result: DataTableColumnWidths = {}

  for (const field in newColumnWidths) {
    result[field] = currentColumnWidths[field] ?? newColumnWidths[field]
  }

  return result
}
