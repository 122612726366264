import { getLocaleFromLocalStorage } from '~/lib/i18n'
import { getTenantCurrencyFromLocalStorage } from './tenant'

export const integerFormatter: Record<string, Intl.NumberFormat> = {}

export function formatInteger(value: number, locale = getLocaleFromLocalStorage()) {
  if (!(locale in integerFormatter)) {
    integerFormatter[locale] = new Intl.NumberFormat(locale, {
      minimumFractionDigits: 0,
    })
  }

  return integerFormatter[locale].format(value)
}

const decimalsFormatter: Record<string, Intl.NumberFormat> = {}

export function formatDecimal(value: number, locale = getLocaleFromLocalStorage()) {
  if (!(locale in decimalsFormatter)) {
    decimalsFormatter[locale] = new Intl.NumberFormat(locale, {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
  }

  return decimalsFormatter[locale].format(value)
}

const integerCompactFormatter: Record<string, Intl.NumberFormat> = {}

export function formatIntegerCompact(value: number, locale = getLocaleFromLocalStorage()) {
  if (!(locale in integerCompactFormatter)) {
    integerCompactFormatter[locale] = new Intl.NumberFormat(locale, {
      notation: 'compact',
      maximumFractionDigits: 0,
    })
  }

  return integerCompactFormatter[locale].format(value)
}

const currencyFormatters: Record<string, Intl.NumberFormat> = {}

type FormatCurrencyOptions = {
  numberFormatOptions?: Pick<Intl.NumberFormatOptions, 'minimumFractionDigits'>
  locale?: string
  currency?: string
}

export function formatCurrency(
  value: number,
  {
    locale = getLocaleFromLocalStorage(),
    currency = getTenantCurrencyFromLocalStorage(),
    numberFormatOptions: { minimumFractionDigits = 2 } = {},
  }: FormatCurrencyOptions = {},
) {
  const formatterKey = `${locale}|${currency}`

  if (!(currency in currencyFormatters)) {
    currencyFormatters[formatterKey] = new Intl.NumberFormat(locale, {
      style: 'currency',
      currency,
      minimumFractionDigits,
      currencyDisplay: 'narrowSymbol',
    })
  }

  return currencyFormatters[formatterKey].format(value)
}

const currencyCompactFormatters: Record<string, Intl.NumberFormat> = {}

export function formatCurrencyCompact(
  value: number,
  {
    locale = getLocaleFromLocalStorage(),
    currency = getTenantCurrencyFromLocalStorage(),
  }: FormatCurrencyOptions = {},
) {
  const formatterKey = `${locale}|${currency}`

  if (!(currency in currencyCompactFormatters)) {
    currencyCompactFormatters[formatterKey] = new Intl.NumberFormat(locale, {
      style: 'currency',
      currency: currency,
      notation: 'compact',
      currencyDisplay: 'narrowSymbol',
    })
  }

  return currencyCompactFormatters[formatterKey].format(value)
}

export const parseFormattedNumber = (strValue: string, fallbackValue: number, decimals = 0) => {
  const parseString = strValue.replace(/\D/g, '')
  let parsedNumber = parseString ? Number(parseString) : 0
  if (parsedNumber > Number.MAX_SAFE_INTEGER) return fallbackValue

  if (decimals === 2) parsedNumber /= 100

  return parsedNumber
}

export function safeNumber(value: number | null | undefined): number {
  return value || 0
}

export function formatPercentage(value: number): string {
  return `${formatDecimal(value)}%`
}
