import type { LinkProps } from '@tanstack/react-router'
import { createLink } from '@tanstack/react-router'
import { forwardRef } from 'react'
import { Button, type ButtonProps } from '../Button'

type LinkButtonProps = ButtonProps & LinkProps

export const LinkButton = createLink(
  forwardRef<HTMLButtonElement, LinkButtonProps>(function LinkButtonWrapper(props, ref) {
    return <Button {...props} disabled={!!props['aria-disabled']} ref={ref} />
  }),
)
