import { Tab as BaseTab } from '@mui/base/Tab'
import { TabPanel as BaseTabPanel } from '@mui/base/TabPanel'
import { Tabs as BaseTabs } from '@mui/base/Tabs'
import { TabsList as BaseTabsList } from '@mui/base/TabsList'
import clsx from 'clsx/lite'
import { LinkAdapter } from '../LinkAdapter'
import type { TabsProps } from './types'

export const Tabs = (props: TabsProps) => {
  return (
    <BaseTabs
      className={clsx(props.className)}
      defaultValue={props.defaultValue}
      value={props.value}
      onChange={(_event, value) => {
        props.onChange?.(value)
      }}
    >
      <BaseTabsList
        slotProps={{
          root: {
            className: 'gap-9.5 flex',
          },
        }}
        slot={props.asNav ? 'nav' : undefined}
      >
        {props.items.map((item) => {
          const { content, icon, ...rest } = item

          return (
            <BaseTab
              key={props.asNav ? item.to : item.value}
              {...rest}
              value={props.asNav ? item.to : item.value}
              slots={{
                root: props.asNav ? LinkAdapter : rest.slots?.root,
              }}
              slotProps={{
                root: (ownerState) => ({
                  className: clsx(
                    'text-body-2 inline-flex h-7.5 items-center gap-2 border-b-2 pb-1.5 transition-colors duration-300',
                    ownerState.selected
                      ? 'text-body-2-strong border-azure'
                      : 'text-body-2 border-transparent',
                  ),
                }),
              }}
            >
              {icon}
              {item.children}
            </BaseTab>
          )
        })}
      </BaseTabsList>
      {props.showContent &&
        props.items.map((item) => {
          return (
            <BaseTabPanel key={item.value} value={item.value}>
              {item.content}
            </BaseTabPanel>
          )
        })}
    </BaseTabs>
  )
}
