import type { Identifier, Pattern } from 'acorn'
import { parse } from 'acorn'
import { simple } from 'acorn-walk'
import type { ApiColDef, ApiColumnCalculated } from '~/types/apiContracts'

// Allowed built-in functions, properties, and values
const allowedGlobals = new Set([
  'Math',
  'Number',
  'parseInt',
  'parseFloat',
  'NaN',
  'Infinity',
  'isNaN',
  'isFinite',
])
const allowedMathProperties = Object.getOwnPropertyNames(Math)
const allowedNumberProperties = Object.getOwnPropertyNames(Number)

function removeNewlines(str: string): string {
  return str.replace(/\\n/g, '')
}

function trimWhiteSpace(serializedFunction: string) {
  return serializedFunction.replace(/\s+/g, ' ')
}

function clearSerializedFunction(serializedFunction: string) {
  return trimWhiteSpace(removeNewlines(serializedFunction))
}

export function validateSerializedFunction(serializedFunction: string): boolean {
  try {
    const ast = parse(`${serializedFunction}`, { ecmaVersion: 2022 })

    if (ast.body.length !== 1) {
      throw new Error('Code must contain a single expression.')
    }
    const firstNode = ast.body[0]
    let isFunction = false
    let functionParams: Pattern[] = []

    // Check if the node is a FunctionDeclaration
    if (firstNode.type === 'FunctionDeclaration') {
      isFunction = true
      functionParams = firstNode.params
    }

    // Check if the node is a VariableDeclaration with an ArrowFunctionExpression
    if (firstNode.type === 'VariableDeclaration' && firstNode.declarations.length === 1) {
      const declaration = firstNode.declarations[0]
      if (declaration.init && declaration.init.type === 'ArrowFunctionExpression') {
        isFunction = true
        functionParams = declaration.init.params
      }
    }

    if (!isFunction) {
      throw new Error('The expression must be a function.')
    }

    if (functionParams.length !== 1) {
      throw new Error('Function must have a single parameter.')
    }

    const firstParam = functionParams[0] as Identifier

    const functionParamName = firstParam.name

    let hasReturnStatement = false
    const declaredVariables = new Set<string>()

    simple(ast, {
      VariableDeclarator(node) {
        if (node.id.type === 'Identifier') {
          declaredVariables.add(node.id.name)
        }
      },

      Identifier(node) {
        // Check for disallowed global variables
        if (
          !allowedGlobals.has(node.name) &&
          node.name !== 'arguments' &&
          node.name !== functionParamName &&
          !declaredVariables.has(node.name)
        ) {
          throw new Error(`Disallowed global or variable access: ${node.name}`)
        }
      },

      MemberExpression(node) {
        // Check that we're only accessing properties of the parameter or allowed globals
        if (node.object.type === 'Identifier') {
          const objectName = node.object.name

          if (!allowedGlobals.has(objectName) && objectName !== functionParamName) {
            throw new Error(`Disallowed object access: ${objectName}`)
          }

          if (
            objectName === 'Math' &&
            node.property.type === 'Identifier' &&
            !allowedMathProperties.includes(node.property.name)
          ) {
            throw new Error(`Disallowed Math property: ${node.property.name}`)
          }

          if (
            objectName === 'Number' &&
            node.property.type === 'Identifier' &&
            !allowedNumberProperties.includes(node.property.name)
          ) {
            throw new Error(`Disallowed Number property: ${node.property.name}`)
          }
        }
      },

      AssignmentExpression(node) {
        if (node.left.type === 'Identifier') {
          const variableName = node.left.name
          if (variableName !== functionParamName && !declaredVariables.has(variableName)) {
            throw new Error(`Assignment to undeclared variable: ${variableName}`)
          }
        }
      },

      CallExpression(node) {
        if (
          node.callee.type === 'MemberExpression' &&
          node.callee.object.type === 'Identifier' &&
          node.callee.object.name === functionParamName
        ) {
          throw new Error(
            `Calling properties of the parameter as functions is not allowed: ${(node.callee.property as Identifier).name}`,
          )
        }
      },

      ReturnStatement() {
        hasReturnStatement = true
      },
    })

    // Ensure a return statement exists
    if (!hasReturnStatement) {
      throw new Error('Missing return statement.')
    }

    return true
  } catch (error) {
    return false
  }
}

export type CalculatedColumnParsedSerializedFunction = (param: unknown) => number

/**
 * Convert a serialized function string into a function.
 */
function deserializeFunction(serializedFunction: string): CalculatedColumnParsedSerializedFunction {
  return new Function('return ' + serializedFunction)()
}

/**
 * @throws CalculatedColumnError
 */
export function parseSerializedFunction(serializedFunction: string) {
  const clearedFunction = clearSerializedFunction(serializedFunction)

  const isValid = validateSerializedFunction(clearedFunction)

  if (!isValid) {
    throw new CalculatedColumnError('INVALID_FORMULA')
  }

  return deserializeFunction(clearedFunction)
}

/**
 * @throws CalculatedColumnError
 */
export function evaluateParsedFunction(
  parsedFunction: CalculatedColumnParsedSerializedFunction,
  values: unknown,
): number {
  try {
    const result = Number(parsedFunction(values))

    if (Number.isNaN(result)) {
      throw new CalculatedColumnError('FORMULA_ERROR')
    }

    return result
  } catch (error) {
    throw new CalculatedColumnError('FORMULA_ERROR')
  }
}

export function checkIfColumnIsCalculated(
  column: ApiColDef,
): column is ApiColDef & ApiColumnCalculated {
  return column.type === 'CALCULATED' && !!column.formula
}

/**
 * @throws CalculatedColumnError
 */
export function getCalculatedColumnValue(apiCol: ApiColumnCalculated, values: unknown) {
  const parsedFunction = parseSerializedFunction(apiCol.formula)

  return evaluateParsedFunction(parsedFunction, values)
}

export type CalculatedColumnErrorCode = 'INVALID_FORMULA' | 'FORMULA_ERROR'

export class CalculatedColumnError extends Error {
  constructor(message: CalculatedColumnErrorCode) {
    super(message)
    this.name = 'CalculatedColumnError'
  }
}
